<template>
    <div class="container" :style="{left:selectSide==='left'?'75px':'0',right:selectSide==='right'?'75px':'0'}" style="padding:20px;">
        <div class="each_rows">
            <div class="left">
                <p>
                    基本设置
                </p>
            </div>

            <div class="right">
                <p>
                    宝贝排序：<label style="cursor:pointer;">
                        <el-checkbox v-model="config.basic.goods_sort" @change="save"></el-checkbox>
                            订单中宝贝信息按商家编码排序
                        </label>
                </p>
                <p>
                    检查可达：<label style="cursor:pointer;">
                        <el-checkbox v-model="config.basic.check_access" @change="save"></el-checkbox>
                            在打印电子面单前检测订单可达状态
                        </label>
                </p>
                <!-- <p>
                    过滤字段：<label style="cursor:pointer;">
                        <el-checkbox v-model="basic.check_access"></el-checkbox>
                            打印时宝贝属性信息过滤
                        </label>
                </p> -->
                <p>
                    备注单号：<label style="cursor:pointer;">
                        <el-checkbox v-model="config.basic.remarks_number" @change="save"></el-checkbox>
                            自动将一单多包的运单号添加到卖家备注中
                        </label>
                </p>

                <!-- <p>
                    宝贝简称：<label style="cursor:pointer;">
                        <el-checkbox v-model="basic.check_access"></el-checkbox>
                            打印发货模块下的宝贝标题显示为宝贝简称（包括订单列表、核单发货、拣货列表、记录下载表格等）
                        </label>
                </p> -->

                <p>
                    打印刷新：<label style="cursor:pointer;">
                        <el-checkbox v-model="config.basic.print_refresh" @change="save"></el-checkbox>
                            在“打印发货 — 快递单/发货单未打印”页面，打印完快递单/发货单后不自动刷新页面
                        </label>
                </p>

                <p>
                    打印保护：<label style="cursor:pointer;">
                        <el-checkbox v-model="config.basic.print_protection" @change="save"></el-checkbox>
                            开启打印保护模式，防重打、漏打（勾选后，批量打印电子面单，打印时间中的页码都为1/1）
                        </label>
                </p>
                <p>
                    订单检测：<label style="cursor:pointer;">
                        <el-checkbox v-model="config.basic.check_order" @change="save"></el-checkbox>
                            开启后会自动检测打印发货订单是否同步，不同步会自动执行同步操作，保证订单完整
                        </label>
                </p>

                <p>
                    打印属性：<label style="cursor:pointer;">
                        <el-checkbox v-model="config.basic.print_attribute_prefix" @change="save"></el-checkbox>
                            打印商品属性前缀
                        </label>
                </p>

                <!-- <p>
                    发票信息：<label style="cursor:pointer;">
                        <el-checkbox v-model="basic.check_access"></el-checkbox>
                            开启后会自动同步或读取订单发票申请信息
                        </label>
                </p>

                <p>
                    村镇打印：<label style="cursor:pointer;">
                        <el-checkbox v-model="basic.check_access"></el-checkbox>
                            开启后打印电子面单中的收件人地址村镇信息
                        </label>
                </p> -->
            </div>
        </div>

        <div class="each_rows">
            <div class="left">
                <p>
                    合并订单
                </p>
            </div>
            <div class="right">
                <p>
                    <label style="cursor:pointer;">
                        <el-checkbox v-model="config.merge.automatic.checked" @change="save"></el-checkbox>
                            相同买家旺旺且相同收货地址的订单自动合并
                        </label>
                </p>
                <p style="margin-left:15px;">
                    <label style="cursor:pointer;color:#999;">
                    <el-radio label="1" v-model="config.merge.automatic.value" @change="save"></el-radio>
                        严格规则 （买家旺旺、收件人、收货地址、手机号码、固定电话、邮编、订单打印状态，必须相同）
                    </label>
                </p>
                <p style="margin-left:15px;">
                    <label style="cursor:pointer;color:#999;">
                        <el-radio label="2" v-model="config.merge.automatic.value" @change="save"></el-radio>
                        宽松规则 （买家旺旺、收件人、收货地址、订单打印状态，必须相同；手机号码、固定电话、邮编，不做判断）
                        </label>
                </p>
            </div>
        </div>

    </div>
</template>

<script>
import data from "./objectData.js";
import method from "./method.js";

export default {
    name: "ListNei",
    inject: ['selectSide'], 
    data() {
        return data;
    },
    mounted() {
        this.init();
    },
    methods:method,
    components:{
    }
}
</script>

<style scoped lang="less">
@import url('../../../assets/less/normal');
.each_rows{
    display:flex;padding:10px 0;
    .left{
        width:100px;
    }
    .right{
        p{
            padding:6px 5px;
        }
    }
}
</style>