import apis from "../../common/js/api2";
import tools from "../../../extends/tools";

export default {
    init() {
        // 获取配置
        apis.getPrintSet().then(res => {
            if (tools.msg(res, this)) {
                this.config = res.data;
                // this.encode();
            }
        }).catch(err => {
            tools.err(err, this);
        });
    },
    save() {
        // 将1和0转义
        // let config = this.decode(this.config);
        // this.basic.print_attribute_prefix = this.basic.print_attribute_prefix ? 1 : 0;
        let saveData = {
            basic: JSON.stringify(this.config.basic),
            merge: JSON.stringify(this.config.merge),
            shipping: JSON.stringify(this.config.shipping)
        };
        apis.setPrintSet(saveData).then(res => {
            tools.msg(res, this, 1);
        }).catch(err => {
            tools.err(err, this);
        });
    }
    // decode() {
    //     let config = tools.copy(this.config);

    //     for (let i in config) {
    //         config.basic[i] = config.basic[i] ? 1 : 0;
    //     }
    //     config.merge.automatic.checked = config.merge.automatic.checked ? 1 : 0;

    //     return config;
    // },
    // encode() {
    //     for (let i in this.config.basic) {
    //         this.config.basic[i] = !!this.config.basic[i];
    //     }
    //     this.config.merge.automatic.checked = !!this.config.merge.automatic.checked;

    // }
}