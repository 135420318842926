<template>
    <div class="flex_box" style="">
        <top></top>
        <nav-bar></nav-bar>
        <list-nei></list-nei>
    </div>
</template>

<script>
    import NavBar from "../../components/common/NavBar";
    import Top from "../../components/common/top";
    import listNei  from "./config/listNei";
    export default {
        data(){
            return {
            }
        },
        methods:{

        },
        name: "printList",
        mounted(){

        },
        components:{
            listNei,
            NavBar,
            Top
        },
    }

</script>