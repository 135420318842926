export default {
    config: {
        basic: {},
        merge: {
            automatic: {
                checked: 0,
                value: 1
            }
        }
    }

}